import React, { useEffect, useState } from "react";
import axios from "axios";
import "./profileitem.css";

const url =
  "https://nwtcrqi6ij.execute-api.ap-east-1.amazonaws.com/signUpUserEmail";

const Footer = (props) => {
  const [emailInput, setEmailInput] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [signUpLoadingText, setSignUpLoadingText] = useState("");

  const onSignUpEmailButtonClick = async (e) => {
    if (isSigningUp) return;

    const pattern =
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const isValid = pattern.test(emailInput);

    if (!isValid) {
      setShowError(true);
      setErrorText("Invalid Email");
      return;
    }
    setShowError(false);
    setErrorText("");

    setSignUpLoadingText("Signing Up...");
    setIsSigningUp(true);

    const res = await axios.put(url, {
      email: emailInput,
    });
    console.log(res);

    setIsSigningUp(false);
    if (!res || !res.data || !res.data.result === "OK") {
      setShowError(true);
      setErrorText("Error Timeout");
      setSignUpLoadingText("");
    }

    setSignUpLoadingText("Done!");
  };

  const onEmailInputChange = (e) => {
    setEmailInput(e.target.value);
  };

  return (
    <div className="text-center px-2 pt-10 w-full ">
      <div className="flex justify-center mb-3 ">
        <input
          className="block border-2 border-grey rounded-lg p-1 px-3 min-w-[280px]"
          type="email"
          placeholder="email"
          value={emailInput}
          onChange={onEmailInputChange}
        />
      </div>
      <button
        className=" text-center justify-center bg-black text-white inline-block border-2 border-white rounded-lg px-3 py-1 hover:bg-[#303fe5] hover:border-[#2232e3] hover:shadow-lg min-w-[180px]"
        onClick={onSignUpEmailButtonClick}
      >
        {signUpLoadingText || "Sign up for NFTC beta"}
      </button>
      {showError && (
        <div className="text-red-600 mt-2 font-bold">{errorText}</div>
      )}
      <div className="mt-4 mb-2 block ">
        <strong className="text-sky-300 font-bold">
          © 2022
          <a href="" className="text-[#2d2d2d] hover:underline">
            {" Msoap.io "}
          </a>
          Proudly Built with{" "}
          <a href="" className="text-[#2d2d2d] hover:underline">
            Ethereum
          </a>
          <span className="text-[#303fe5] "> ♥</span>
        </strong>
      </div>
    </div>
  );
};

export default Footer;
