import React from "react";

import TwitterIcon from "../../assets/images/twitter.svg";
import GithubIcon from "../../assets/images/github.svg";
import MediumIcon from "../../assets/images/medium.svg";
import WechatIcon from "../../assets/images/wechat.svg";
import TelegramIcon from "../../assets/images/telegram.svg";
import PaypalIcon from "../../assets/images/paypal.svg";
import WhatsappIcon from "../../assets/images/whatsapp.svg";
import FacebookIcon from "../../assets/images/facebook.svg";
import InstagramIcon from "../../assets/images/instagram.svg";
import LineIcon from "../../assets/images/line.svg";

const socialMediaIcons = {
  twitter: { icon: TwitterIcon, alt: "Twitter Logo" },
  github: { icon: GithubIcon, alt: "GitHub Logo" },
  medium: { icon: MediumIcon, alt: "Medium Logo" },
  wechat: { icon: WechatIcon, alt: "Wechat Logo" },
  telegram: { icon: TelegramIcon, alt: "Telegram Logo" },
  paypal: { icon: PaypalIcon, alt: "Paypal Logo" },
  whatsapp: { icon: WhatsappIcon, alt: "Whatsapp Logo" },
  facebook: { icon: FacebookIcon, alt: "Facebook Logo" },
  instagram: { icon: InstagramIcon, alt: "Instagram Logo" },
  line: { icon: LineIcon, alt: "Line Logo" },
};

const SocialMediaLinks = (props) => {
  return (
    <div class="profile-social">
      {props.items.map((item) => {
        if (item && item.platform && socialMediaIcons[item.platform]) {
          return (
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              class="profile-social-item tooltip test"
              title={item.platform}
            >
              <img
                src={socialMediaIcons[item.platform].icon}
                alt={socialMediaIcons[item.platform].alt}
                height="32px"
                width="32px"
              />
            </a>
          );
        } else {
          return <a href="" class="profile-social-item tooltip test"></a>;
        }
      })}
    </div>
  );
};

export default SocialMediaLinks;
