import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";

// pages
import HomePage from "./containers/home/HomePage";
import Error404Page from "./containers/error/Error404Page";
import MockPage from "./containers/mock/MockPage";
import ProfileItem from "./containers/home/ProfileItem.jsx";

class Router extends Component {
    render() {
        return (
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/:userId" element={<HomePage />} />
                <Route path="/mock" element={<MockPage />} />
                {/* <Route path="/profile" element={<ProfileItem />} /> */}
                <Route path="*" element={Error404Page}></Route>
            </Routes>
        );
    }
}

export default Router;
