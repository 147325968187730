import React from "react";
import "./profileitem.css";

const ProfileItem = (props) => {
    return (
        <div className="box-border block flex-1 flex flex-col ">
            <div className=" max-w-5xl mx-auto w-full">
                <div className="items-center flex flex-col text-center">
                    <div className="flex flex-col justify-center max-w-lg w-full mt-0 py-5">
                        {props.items.map((item) => {
                            return (
                                <div className=" items-center bg-[#fff] cursor-pointer flex rounded-xl my-2.5 py-4 px-5 transition-all hover:-translate-y-0.5 hover:opacity-100 hover:shadow-lg near">
                                    <div className=" items-center flex text-left break-all w-full">
                                        {/* <svg
                                    className="block h-6 w-6"
                                    fill="none"
                                    height="256"
                                    viewBox="0 0 256 256"
                                    width="256"
                                    xmlns="http://www.w3.org/2000/svg"
                                    alt="NEAR"
                                >
                                    <path
                                        d="m0 0h256v256h-256z"
                                        fill="#fff"
                                    ></path>
                                    <path
                                        d="m188.266 38.2222-41.731 61.9998c-2.886 4.222 2.664 9.334 6.659 5.778l41.066-35.7778c1.11-.8889 2.663-.2222 2.663 1.3334v111.7774c0 1.556-1.997 2.223-2.885 1.111l-124.3066-148.8884c-3.9956-4.8889-9.7669-7.5556-16.2042-7.5556h-4.4395c-11.5427 0-21.0877 9.5556-21.0877 21.3333v157.3337c0 11.777 9.545 21.333 21.3097 21.333 7.3252 0 14.2064-3.778 18.202-10.222l41.7313-62c2.886-4.222-2.664-9.334-6.659-5.778l-41.0657 35.556c-1.1099.888-2.6637.222-2.6637-1.334v-111.5553c0-1.5556 1.9978-2.2223 2.8857-1.1111l124.3067 148.8884c3.995 4.889 9.989 7.556 16.204 7.556h4.439c11.765 0 21.31-9.556 21.31-21.333v-157.3337c0-11.7777-9.545-21.3333-21.31-21.3333-7.547 0-14.428 3.7778-18.424 10.2222z"
                                        fill="#000"
                                    ></path>
                                </svg> */}
                                        <div className="flex-auto pl-0">
                                            <div className=" text-[#b9b9b9] text-[10px] font-medium leading-3 select-none">
                                                {item.title}
                                            </div>
                                            <div className="text-[14px] font-medium leading-4 select-all">
                                                {item.description}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="items-center flex near1">
                                        <button
                                            className="btn1 text-xs h-6 px-1.5 mr-1"
                                            onClick={(e) => {
                                                navigator.clipboard.writeText(
                                                    item.link
                                                );
                                            }}
                                        >
                                            <svg
                                                className=" h-5 mt-0.5 w-5"
                                                width="24"
                                                height="24"
                                                stroke-width="1.5"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M19.4 20H9.6C9.26863 20 9 19.7314 9 19.4V9.6C9 9.26863 9.26863 9 9.6 9H19.4C19.7314 9 20 9.26863 20 9.6V19.4C20 19.7314 19.7314 20 19.4 20Z"
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M15 9V4.6C15 4.26863 14.7314 4 14.4 4H4.6C4.26863 4 4 4.26863 4 4.6V14.4C4 14.7314 4.26863 15 4.6 15H9"
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                            </svg>
                                        </button>
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn1 text-xs h-6 px-1.5 ml-1"
                                        >
                                            <svg
                                                className=" h-5 mt-0.5 w-5"
                                                width="24"
                                                height="24"
                                                stroke-width="1.5"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8 21H20.4C20.7314 21 21 20.7314 21 20.4V3.6C21 3.26863 20.7314 3 20.4 3H3.6C3.26863 3 3 3.26863 3 3.6V16"
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M3.5 20.5L12 12M12 12V16M12 12H8"
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileItem;
