import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ProfileItem from "./ProfileItem";
import SocialMediaLinks from "./SocialMediaLinks";
import Footer from "./Footer";
import axios from "axios";

const url =
  "https://nwtcrqi6ij.execute-api.ap-east-1.amazonaws.com/getNftcUserData";

function HomePage(props) {
  // const [userId, setUserId] = useState("justin");

  const [prifilePicUrl, setPrifilePicUrl] = useState("");
  const [profileName, setProfileName] = useState("Loading");
  const [profileDescription, setProfileDescription] = useState("");
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [profileItems, setProfileItems] = useState([]);

  let { userId } = useParams();

  useEffect(async () => {
    const requestUrl = `${url}?userId=${userId || "justin"}`;

    const res = await axios.get(requestUrl);
    // console.log(res.data);
    if (!res.data) {
      console.log("userData not exist");
      setProfileName("Error");
      return;
    }

    const userData = res.data;

    setPrifilePicUrl(userData.prifilePicUrl);
    setProfileName(userData.profileName);
    setProfileDescription(userData.profileDescription);
    setSocialMediaLinks(userData.socialMediaLinks);
    setProfileItems(userData.customLinks);
  }, []);

  return (
    <div className="h-full bg-[url('/src/assets/images/background.jpeg')] bg-no-repeat bg-cover">
      <main className="h-full container mx-auto">
        <div class="nftcio-container flex flex-col">
          <div class="nftcio-header">
            <div class="container grid-lg">
              <div class="columns">
                <div class="column col-12">
                  <a class="nftcio-logo" title="NFTC.IO" href="">
                    <h1>
                      NFTC
                      <br />
                      IO
                    </h1>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="nftcio-cover flare"></div>
          <div class="nftcio-contect container grid-lg">
            <div class="nftcio-profile text-center">
              {prifilePicUrl && (
                <img
                  src={prifilePicUrl}
                  class="profile-avatar avatar avatar-xl"
                ></img>
              )}
              <h2 class="profile-name">{profileName}</h2>
              <h3 class="profile-description mt-2">{profileDescription}</h3>

              <SocialMediaLinks items={socialMediaLinks} />
            </div>
          </div>
          <ProfileItem items={profileItems} />
          <Footer />
        </div>
      </main>
    </div>
  );
}
export default HomePage;
